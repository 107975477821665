import styled from "@emotion/styled";

export const DISPLAY_SIZES = {
  MOBILE_S: "320px",
  MOBILE_M: "375px",
  MOBILE_L: "425px",
  TABLET: "768px",
  LAPTOP: "1024px",
  LAPTOP_L: "1440px",
  DESKTOP: "1920px",
  DESKTOP_L: "2560px",
  DESKTOP_XL: "3840px",
};

export const Container = styled.div`
  background-color: #383a5a;
  min-height: 100vh;
  width: 100vw;
  color: #fff;
  box-sizing: border-box;
`;

export const Header = styled.div`
  padding: 32px 80px;
  font-size: 64px;
  box-sizing: border-box;

  display: flex;
  align-items: center;
  justify-content: space-around;

  @media (max-width: ${DISPLAY_SIZES.TABLET}) {
    padding: 16px;
    font-size: 32px;
  }
`;

export const Content = styled.div`
  padding: 32px 80px;
  box-sizing: border-box;

  @media (max-width: ${DISPLAY_SIZES.TABLET}) {
    padding: 16px;
  }
`;

export const Socials = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 24px;
  margin-top: 80px;

  @media (max-width: ${DISPLAY_SIZES.TABLET}) {
    flex-wrap: wrap;
    justify-content: center;
  }
`;

export const SocialLogoContainer = styled.div`
  height: 120px;
  width: 120px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: ${DISPLAY_SIZES.TABLET}) {
    height: 80px;
    width: 80px;
  }
`;

export const SocialLogo = styled.img<{ dexscreener?: boolean }>`
  height: 120px;
  width: 120px;
  transition: 0.2s height, 0.2s width, 0.2s opacity;
  border-radius: 50%;
  cursor: pointer;

  ${({ dexscreener }) =>
    dexscreener
      ? `
    background: #000;
    box-sizing: border-box;
    padding: 16px;
  `
      : ""}

  filter: drop-shadow(0px 0px 8px rgba(0, 0, 0, 0.3));

  @media (max-width: ${DISPLAY_SIZES.TABLET}) {
    height: 75px;
    width: 75px;
  }
`;

export const MamaMiaLogo = styled.img`
  width: 180px;
`;

export const MarioLogo = styled.img`
  height: 30vh;
`;

export const Memes = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 8px;
`;

export const ChrisImg = styled.img`
  height: 280px;
`;

export const Btn = styled.button`
  padding: 8px 48px;
  padding-top: 16px;
  background-color: transparent;
  color: white;
  border: 4px solid white;
  font-family: "Luckiest Guy";
  border-radius: 16px;
  font-size: 32px;
  line-height: 100%;
  margin-bottom: 16px;
  cursor: pointer;
  display: flex;
  align-items: center;

  transition: 0.2s transform;

  :hover {
    transform: scale(1.05);
  }

  :active {
    transform: scale(0.95);
  }
`;
