import React from "react";
import "./App.css";
import {
  Btn,
  ChrisImg,
  Container,
  Content,
  Header,
  MamaMiaLogo,
  MarioLogo,
  Memes,
  SocialLogo,
  SocialLogoContainer,
  Socials,
} from "./App.styles";

import twitter from "./twitter.png";
import tg from "./tg.png";
import dexscreener from "./dexscreener.svg";

import mamaMiaLogo from "./mamamia.png";
import mario from "./mario.png";
import chris from "./chris.jpeg";

const BUY_NOW_LINK = "";
const TWITTER_LINK = "https://twitter.com/";
const TELEGRAM_LINK = "https://t.me/";
const DEXSCREENER_LINK = "https://dexscreener.com/base/";

function App() {
  const onBuyNow = () => window.open(BUY_NOW_LINK, "_blank");
  return (
    <Container>
      <Header>
        <MamaMiaLogo src={mamaMiaLogo} />
        <Btn onClick={onBuyNow}>BUY NOW</Btn>
      </Header>

      <Content>
        <Memes>
          <MarioLogo src={mario} />
          <ChrisImg src={chris} />
        </Memes>
        <Socials>
          <SocialLogoContainer
            onClick={() => window.open(TWITTER_LINK, "_blank")}
          >
            <SocialLogo src={twitter} />
          </SocialLogoContainer>
          <SocialLogoContainer
            onClick={() => window.open(TELEGRAM_LINK, "_blank")}
          >
            <SocialLogo src={tg} />
          </SocialLogoContainer>
          <SocialLogoContainer
            onClick={() => window.open(DEXSCREENER_LINK, "_blank")}
          >
            <SocialLogo dexscreener src={dexscreener} />
          </SocialLogoContainer>
        </Socials>
      </Content>
    </Container>
  );
}

export default App;
